<template>
	<main>
		<section class="relative container text-center py-32 z-10">
			<h1 class="mb-16 tracking-wide">
				{{ pageText[locale].choosecountry }}
			</h1>
			<div class="flex justify-center space-x-10">
				<div class="flex flex-col">
					<nuxt-link
						:to="`https://hokify.at${path}`"
						class="inline-block"
						@click="saveCountryPreference('at')"
					>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600" class="w-24 rounded-full">
							<rect fill="#f00" width="600" height="600"></rect>
							<rect fill="#fff" y="200" width="600" height="200"></rect>
						</svg>
					</nuxt-link>
					<nuxt-link
						:to="`https://hokify.at${path}`"
						class="mt-3 hover:underline hover:text-color-main-hover inline-block"
						@click="saveCountryPreference('at')"
					>
						{{ locale === 'en' ? 'Austria' : 'Österreich' }}
					</nuxt-link>
				</div>
				<div class="flex flex-col">
					<nuxt-link
						:to="`https://hokify.de${path}`"
						class="inline-block"
						@click="saveCountryPreference('de')"
					>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3 3" class="w-24 rounded-full">
							<rect width="3" height="3" y="0" x="0" fill="#000"></rect>
							<rect width="3" height="2" y="1" x="0" fill="#D00"></rect>
							<rect width="3" height="1" y="2" x="0" fill="#FFCE00"></rect>
						</svg>
					</nuxt-link>
					<nuxt-link
						:to="`https://hokify.de${path}`"
						class="mt-3 hover:underline hover:text-color-main-hover inline-block"
						@click="saveCountryPreference('de')"
					>
						{{ locale === 'en' ? 'Germany' : 'Deutschland' }}
					</nuxt-link>
				</div>
				<div class="flex flex-col">
					<nuxt-link
						:to="`https://hokify.ch${path}`"
						class="inline-block"
						@click="saveCountryPreference('ch')"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							version="1.1"
							viewBox="0 0 320 320"
							preserveAspectRatio="xMidYMid meet"
							xml:lang="de"
							class="w-24 rounded-full"
						>
							<rect fill="#f00" height="320" width="320"></rect>
							<rect fill="#fff" height="60" width="200" x="60" y="130"></rect>
							<rect fill="#fff" height="200" width="60" x="130" y="60"></rect>
						</svg>
					</nuxt-link>
					<nuxt-link
						:to="`https://hokify.ch${path}`"
						class="mt-3 hover:underline hover:text-color-main-hover inline-block"
						@click="saveCountryPreference('ch')"
					>
						{{ locale === 'en' ? 'Switzerland' : 'Schweiz' }}
					</nuxt-link>
				</div>
			</div>
		</section>
		<section class="bg-color-main-light">
			<div class="container max-w-5xl text-center py-12 sm:py-16">
				<h4 class="mb-1">{{ pageText[locale].ctatitle }}</h4>
				<p class="mb-8">
					{{ pageText[locale].ctasubtitle }}
				</p>
				<HokButton to="mailto:info@hokify.com" color="blue">
					{{ pageText[locale].ctabutton }}
				</HokButton>
			</div>
		</section>
	</main>
</template>

<script lang="ts">
import { lsTest } from '@hokify/shared-components-nuxt3/lib/helpers/localstorage';
import HokButton from '@hokify/shared-components-nuxt3/lib/components/HokButton.vue';
import { saveCountryPreference } from '../helpers/preferences';
import pageText from '~/data/text.json';

export default defineNuxtComponent({
	name: 'CountrySelectionPage',
	components: { HokButton },
	async asyncData({ req }) {
		let locale = 'de';
		let path = '';

		if (process.client) {
			const newLocale = window.navigator?.language?.split('-')[0];
			if (newLocale !== 'de') {
				locale = 'en';
			}
		} else if (req?.headers['accept-language']) {
			const newLocale = req.headers['accept-language']
				.split(',')[0]
				.toLocaleLowerCase()
				.substring(0, 2);
			if (newLocale !== 'de') {
				locale = 'en';
			}
			if (req?.originalUrl) {
				path = req?.originalUrl;
			}
		}

		return {
			locale,
			path
		};
	},
	data() {
		const countryPreference = undefined as undefined | 'at' | 'de' | 'ch';
		const path = '';

		const locale = 'de';

		return {
			locale,
			path,
			pageText,
			countryPreference
		};
	},
	methods: { saveCountryPreference },
	mounted() {
		if (lsTest()) {
			const storage = localStorage.getItem('country-preference');
			if (typeof storage === 'string') {
				this.countryPreference = JSON.parse(storage);
			}
		}
		this.path = this.$route.fullPath;
		if (this.countryPreference && this.path) {
			window.location.href = `https://hokify.${this.countryPreference}${this.path}`;
		}
	},
	setup() {
		definePageMeta({
			path: '/countryselection',
			alias: [
				// Hotfix fail linking of PrivacyOverview.vue links
				// TODO: fix HokLink component routing. Since `HokLink.vue:130 (func topLevelDomain)` is not working
				// properly for server side generation it falls back to .com instead of the correct domain.
				// After the fix -> Remove /business redirects here
				'/business/agreement/posting',
				'/business/agreement/application',
				'/apply',
				'/hokifycv',
				'/jobalarm',
				'/jobalarm/:jobalarmId/:subId?',
				'/privacy',
				'/a/:alias',
				'/seamless-login/:loginId/:loginToken',
				'/verify/:userid/:code',
				'/app',
				'/pwa',
				'/user',
				'/saved',
				'/review-profile',
				'/user-profile',
				'/user-settings',
				'/notification-settings',
				'/my-cv',
				'/notifications',
				'/webupload/:docType',
				'/match/:matchId',
				'/job/:jobNr/:uref?',
				'/apply/:jobNr',
				'/apply-company/:companyId',
				'/save/:jobNr',
				'/discard/:jobNr',
				'/discard-suggestion/:jobNr',
				'/company',
				'/app/company',
				'/pwa/company',
				'/organization',
				'/demo',
				'/addcredit/:priceIdORCoupon?/:couponORContingent?',
				'/addjob',
				'/addjob/:priceIdORCoupon/:coupon?',
				'/addcredit',
				'/pricelist',
				'/app/company/:jobNr',
				'/app/company/:jobNr/edit',
				'/candidate/:jobNr/:matchId',
				'/candidate-company/:companyId/:matchId',
				'/company-referral',
				'/company-privacy',
				'/candidates/:jobId',
				'/company-profile',
				'/upgradejob/:jobId/:priceIdRCoupon?/:coupon?',
				'/jobclaim/:code/:jobNr',
				'/app/user',
				'/app/login',
				'/jobs/m/:branche/:location',
				'/jobs/k/:branche',
				'/jobs/l/:location',
				'/c/:company',
				'/disclaimer'
			],
			layout: 'headerless'
		});
	}
});
</script>
