import revive_payload_client_J2OLIm19WU from "/home/runner/work/hokify/hokify/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_eslint@8.57.1_sass@1.44.0_typescript@5.1.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_jVi2d8eAAh from "/home/runner/work/hokify/hokify/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_eslint@8.57.1_sass@1.44.0_typescript@5.1.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_lf4UxjzErd from "/home/runner/work/hokify/hokify/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_eslint@8.57.1_sass@1.44.0_typescript@5.1.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_n8Bg0Ve5kg from "/home/runner/work/hokify/hokify/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_eslint@8.57.1_sass@1.44.0_typescript@5.1.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_reusCmOTvB from "/home/runner/work/hokify/hokify/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_eslint@8.57.1_sass@1.44.0_typescript@5.1.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_OW8MO1yJSO from "/home/runner/work/hokify/hokify/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_eslint@8.57.1_sass@1.44.0_typescript@5.1.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Q6esSgnn6K from "/home/runner/work/hokify/hokify/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_eslint@8.57.1_sass@1.44.0_typescript@5.1.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/hokify/hokify/apps/international-page-app/.nuxt/components.plugin.mjs";
import prefetch_client_Khm77Iuoak from "/home/runner/work/hokify/hokify/node_modules/.pnpm/nuxt@3.14.159_@types+node@18.19.64_eslint@8.57.1_sass@1.44.0_typescript@5.1.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import insidePagePlugin_JY7CYx6iLI from "/home/runner/work/hokify/hokify/apps/international-page-app/src/plugins/insidePagePlugin.ts";
import ismobile_d00yZMP9Wz from "/home/runner/work/hokify/hokify/apps/international-page-app/src/plugins/ismobile.ts";
import modalPlugin_J7c0j9qUKn from "/home/runner/work/hokify/hokify/apps/international-page-app/src/plugins/modalPlugin.ts";
import sentry_client_KAXFuL2wum from "/home/runner/work/hokify/hokify/apps/international-page-app/src/plugins/sentry.client.ts";
import snackbar_client_LfkENc4klY from "/home/runner/work/hokify/hokify/apps/international-page-app/src/plugins/snackbar.client.ts";
export default [
  revive_payload_client_J2OLIm19WU,
  unhead_jVi2d8eAAh,
  router_lf4UxjzErd,
  payload_client_n8Bg0Ve5kg,
  navigation_repaint_client_reusCmOTvB,
  check_outdated_build_client_OW8MO1yJSO,
  chunk_reload_client_Q6esSgnn6K,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Khm77Iuoak,
  insidePagePlugin_JY7CYx6iLI,
  ismobile_d00yZMP9Wz,
  modalPlugin_J7c0j9qUKn,
  sentry_client_KAXFuL2wum,
  snackbar_client_LfkENc4klY
]