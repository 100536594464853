import { default as countryselectionutqWVANRhcMeta } from "/home/runner/work/hokify/hokify/apps/international-page-app/src/pages/countryselection.vue?macro=true";
import { default as _403_userextrasT3ApdpflTnMeta } from "/home/runner/work/hokify/hokify/apps/international-page-app/src/pages/errors/403_userextras.vue?macro=true";
import { default as _403iu0t4jyHulMeta } from "/home/runner/work/hokify/hokify/apps/international-page-app/src/pages/errors/403.vue?macro=true";
import { default as _404eW4Kmh6yLCMeta } from "/home/runner/work/hokify/hokify/apps/international-page-app/src/pages/errors/404.vue?macro=true";
import { default as indexojboaTabvgMeta } from "/home/runner/work/hokify/hokify/apps/international-page-app/src/pages/index.vue?macro=true";
export default [
  {
    name: "countryselection",
    path: "/countryselection",
    meta: countryselectionutqWVANRhcMeta || {},
    alias: ["/business/agreement/posting","/business/agreement/application","/apply","/hokifycv","/jobalarm","/jobalarm/:jobalarmId/:subId?","/privacy","/a/:alias","/seamless-login/:loginId/:loginToken","/verify/:userid/:code","/app","/pwa","/user","/saved","/review-profile","/user-profile","/user-settings","/notification-settings","/my-cv","/notifications","/webupload/:docType","/match/:matchId","/job/:jobNr/:uref?","/apply/:jobNr","/apply-company/:companyId","/save/:jobNr","/discard/:jobNr","/discard-suggestion/:jobNr","/company","/app/company","/pwa/company","/organization","/demo","/addcredit/:priceIdORCoupon?/:couponORContingent?","/addjob","/addjob/:priceIdORCoupon/:coupon?","/addcredit","/pricelist","/app/company/:jobNr","/app/company/:jobNr/edit","/candidate/:jobNr/:matchId","/candidate-company/:companyId/:matchId","/company-referral","/company-privacy","/candidates/:jobId","/company-profile","/upgradejob/:jobId/:priceIdRCoupon?/:coupon?","/jobclaim/:code/:jobNr","/app/user","/app/login","/jobs/m/:branche/:location","/jobs/k/:branche","/jobs/l/:location","/c/:company","/disclaimer"],
    component: () => import("/home/runner/work/hokify/hokify/apps/international-page-app/src/pages/countryselection.vue")
  },
  {
    name: "errors-403_userextras",
    path: "/errors/403_private_files",
    meta: _403_userextrasT3ApdpflTnMeta || {},
    alias: ["/userextra/:extra","/userextra%2F%3Aextra"],
    component: () => import("/home/runner/work/hokify/hokify/apps/international-page-app/src/pages/errors/403_userextras.vue")
  },
  {
    name: "errors-403",
    path: "/errors/403",
    component: () => import("/home/runner/work/hokify/hokify/apps/international-page-app/src/pages/errors/403.vue")
  },
  {
    name: "errors-404",
    path: "/errors/404",
    component: () => import("/home/runner/work/hokify/hokify/apps/international-page-app/src/pages/errors/404.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/hokify/hokify/apps/international-page-app/src/pages/index.vue")
  }
]