<template>
	<main>
		<section class="max-w-3xl container text-center py-8 sm:pt-12 sm:pb-16">
			<h1 class="mt-4 text-4xl">Link nicht mehr gültig</h1>
			<picture>
				<source type="image/webp" srcset="~/assets/img/error-robot.webp" />
				<img
					class="error-robot mb-4 inline"
					style="height: 150px"
					src="~/assets/img/error-robot.png"
				/>
			</picture>
			<div>
				<h2>Der verwendete Link ist nicht mehr gültig</h2>
				<span>
					Sie müssen sich einloggen um die angeforderte Datei herunterladen zu können.<br />
					Bitte wählen Sie Ihr Land um fortzufahren:
				</span>
			</div>
			<section class="relative container text-center pt-8 pb-32 z-10">
				<div class="flex justify-center space-x-10">
					<div class="flex flex-col">
						<nuxt-link :to="`https://hokify.at${path}`" class="inline-block">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 600 600"
								class="w-24 rounded-full"
							>
								<rect fill="#f00" width="600" height="600"></rect>
								<rect fill="#fff" y="200" width="600" height="200"></rect>
							</svg>
						</nuxt-link>
						<nuxt-link
							:to="`https://hokify.at${path}`"
							class="mt-3 hover:underline hover:text-color-main-hover inline-block"
						>
							{{ locale === 'en' ? 'Austria' : 'Österreich' }}
						</nuxt-link>
					</div>
					<div class="flex flex-col">
						<nuxt-link :to="`https://hokify.de${path}`" class="inline-block">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3 3" class="w-24 rounded-full">
								<rect width="3" height="3" y="0" x="0" fill="#000"></rect>
								<rect width="3" height="2" y="1" x="0" fill="#D00"></rect>
								<rect width="3" height="1" y="2" x="0" fill="#FFCE00"></rect>
							</svg>
						</nuxt-link>
						<nuxt-link
							:to="`https://hokify.de${path}`"
							class="mt-3 hover:underline hover:text-color-main-hover inline-block"
						>
							{{ locale === 'en' ? 'Germany' : 'Deutschland' }}
						</nuxt-link>
					</div>
					<div class="flex flex-col">
						<nuxt-link :to="`https://hokify.ch${path}`" class="inline-block">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								version="1.1"
								viewBox="0 0 320 320"
								preserveAspectRatio="xMidYMid meet"
								xml:lang="de"
								class="w-24 rounded-full"
							>
								<rect fill="#f00" height="320" width="320"></rect>
								<rect fill="#fff" height="60" width="200" x="60" y="130"></rect>
								<rect fill="#fff" height="200" width="60" x="130" y="60"></rect>
							</svg>
						</nuxt-link>
						<nuxt-link
							:to="`https://hokify.ch${path}`"
							class="mt-3 hover:underline hover:text-color-main-hover inline-block"
						>
							{{ locale === 'en' ? 'Switzerland' : 'Schweiz' }}
						</nuxt-link>
					</div>
				</div>
			</section>
			<span>{{ $route.path }}</span>
		</section>
	</main>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import pageText from '~/data/text.json';

export default defineComponent({
	name: 'Error403PrivateFiles',
	data() {
		return {
			locale: 'de',
			pageText,
			path: '/pwa'
		};
	},
	mounted() {
		// query can contain:
		// - reftype: company or job
		// - refid: companyId or jobId
		// - matchid: the match id
		switch (this.$route?.query?.reftype) {
			case 'job':
				this.path = `/candidate/${this.$route.query.refid}/${this.$route.query.matchid}`;
				break;
			case 'company':
				this.path = `/candidate-company/${this.$route.query.refid}/${this.$route.query.matchid}`;
				break;
			default:
				// after this is rolled out (check after march 2021), check if we can redirect the default case to /pwa instead of /pwa/company
				this.path = '/pwa/company';
		}
	},
	setup() {
		definePageMeta({
			path: '/errors/403_private_files',
			alias: ['/userextra/:extra', '/userextra%2F%3Aextra']
		});
	}
});
</script>
